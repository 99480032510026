<template>
	<SubmitClaimTemplate
		v-if="!loading"
		:percent-complete="50"
		:step-title="$t('title.step')"
		:class="loading ? 'invisible' : ''"
		:fluid="false"
	>
		<BaseForm ref="baseform" v-slot="{ invalid }" automation-id="otherCoverageForm'">
			<BRow v-if="otherCoverage.showIsAccurate">
				<BCol>
					<BaseCard>
						<p class="mb-2">
							{{ $t('otherCoverageLabel.one') }} <b>{{ memberName }}</b>
						</p>
						<p>
							{{ $t('otherCoverageLabel.two') }} <b>{{ cobEffectiveDate }}</b>
						</p>
						<YesNoToggle
							automation-id="otherCoverageRadioGroup"
							:value="otherCoverage.stillAccurate"
							:label="$t('stillAccurateToggleLabel')"
							stacked
							class="mb-0"
							is-required
							@input="onStillAccurateInput"
						></YesNoToggle>
					</BaseCard>
				</BCol>
			</BRow>
			<BRow v-if="otherCoverage.showOtherInsuranceCoverage">
				<BCol>
					<BaseCard>
						<YesNoToggle
							automation-id="otherInsuranceCoverage"
							:value="otherCoverage.otherInsuranceCoverage"
							:label="$t('otherInsuranceCoverageLabel')"
							stacked
							class="mb-0"
							is-required
							@input="onOtherInsuranceCoverageInput"
						></YesNoToggle>
					</BaseCard>
				</BCol>
			</BRow>
			<BRow v-if="showIsBC">
				<BCol>
					<BaseCard>
						<YesNoToggle
							automation-id="otherCoverageBC"
							:value="otherCoverage.otherCoverageBC"
							:label="$t('otherCoverageBCLabel')"
							stacked
							class="mb-0"
							is-required
							@input="onOtherCoverageBCInput"
						></YesNoToggle>
					</BaseCard>
				</BCol>
			</BRow>
			<BRow v-if="showIsBCCoverage">
				<BCol>
					<BaseCard>
						<BaseRadioButtonGroup
							automation-id="otherPlanType"
							:validation-rules="{ required: true }"
							:validation-messages="{ required: $t('error.otherPlanTypeRequired') }"
							:accessible-required-label="$t('error.accessibleRequired')"
							:value="coordinationOfBenefits.cobNewInfoIsIndividual"
							:label="$t('otherPlanTypeLabel')"
							stacked
							class="mb-0"
							is-required
							:options="[
								{ value: true, text: $t('otherPlanTypeOptions.individual') },
								{ value: false, text: $t('otherPlanTypeOptions.group') }
							]"
							@input="onOtherPlanTypeInput"
						></BaseRadioButtonGroup>
					</BaseCard>
				</BCol>
			</BRow>
			<BRow v-if="showIsBCCoverage">
				<BCol>
					<BaseCard
						@click.native="isErrorMsgCoverageFor"
						@keydown.native.tab="isErrorMsgCoverageFor"
					>
						<!-- Added role='group' to add required message for the goup of checkboxes -->
						<p role="group" class="label" :aria-label="$t('error.accessibleRequired')">
							{{ $t('otherCoverageIsFor') }}
						</p>
						<BaseCheckBox
							checkbox-name="otherCoverageTypeHospital"
							class="mb-2"
							:checked="coordinationOfBenefits.cobNewInfoHasHospital"
							:label="$t('otherCoverageTypes.hospital')"
							automation-id="otherCoverageTypeHospital"
							@input="(value) => onOtherCoverageTypeInput(value, 'cobNewInfoHasHospital')"
						/>
						<BaseCheckBox
							checkbox-name="otherCoverageTypeVision"
							class="mb-2"
							:checked="coordinationOfBenefits.cobNewInfoHasVision"
							:label="$t('otherCoverageTypes.vision')"
							automation-id="otherCoverageTypeVision"
							@input="(value) => onOtherCoverageTypeInput(value, 'cobNewInfoHasVision')"
						/>
						<BaseCheckBox
							checkbox-name="otherCoverageTypeExtendedHealth"
							class="mb-2"
							:checked="coordinationOfBenefits.cobNewInfoHasHealth"
							:label="$t('otherCoverageTypes.extendedHealth')"
							automation-id="otherCoverageTypeExtendedHealth"
							@input="(value) => onOtherCoverageTypeInput(value, 'cobNewInfoHasHealth')"
						/>
						<BaseCheckBox
							checkbox-name="otherCoverageTypeDental"
							class="mb-2"
							:checked="coordinationOfBenefits.cobNewInfoHasDental"
							:label="$t('otherCoverageTypes.dental')"
							automation-id="otherCoverageTypeDental"
							@input="(value) => onOtherCoverageTypeInput(value, 'cobNewInfoHasDental')"
						/>
						<BaseCheckBox
							checkbox-name="otherCoverageTypeDrugs"
							class="mb-2"
							:checked="coordinationOfBenefits.cobNewInfoHasDrugs"
							:label="$t('otherCoverageTypes.drugs')"
							automation-id="otherCoverageTypeDrugs"
							@input="(value) => onOtherCoverageTypeInput(value, 'cobNewInfoHasDrugs')"
						/>
						<BaseCheckBox
							checkbox-name="otherCoverageTypeTravel"
							class="mb-0"
							:checked="coordinationOfBenefits.cobNewInfoHasTravel"
							:label="$t('otherCoverageTypes.travel')"
							automation-id="otherCoverageTypeTravel"
							@input="(value) => onOtherCoverageTypeInput(value, 'cobNewInfoHasTravel')"
						/>
						<BFormGroup class="m-0">
							<ValidationProvider rules="required">
								<input v-model="otherCoverage.otherCoverageTypeMinChecked" type="hidden" />
								<div v-if="showError" class="d-block error-message">
									{{ $t('error.otherCoverageIsFor') }}
								</div>
							</ValidationProvider>
						</BFormGroup>
					</BaseCard>
				</BCol>
			</BRow>
			<BRow v-if="showIsBCCoverage">
				<BCol>
					<BaseCard>
						<BRow>
							<BCol cols="12" sm="10">
								<BaseInput
									:label="$t('memberName')"
									class="text-input"
									:value="coordinationOfBenefits.cobNewInfoMemberName"
									:validation-rules="{ required: true }"
									:validation-messages="{ required: $t('error.memberNameRequired') }"
									:max-length="40"
									@input="onMemberNameInput"
								/>
								<CertificateIdInput
									v-if="otherCoverage.otherCoverageBC"
									class="text-input"
									:value="coordinationOfBenefits.cobNewInfoIdNumber"
									@input="onIdNumberInput"
								/>
								<PolicyIdInput
									v-if="otherCoverage.otherCoverageBC"
									class="text-input"
									:value="coordinationOfBenefits.cobNewInfoPolicyId"
									@input="onPolicyNumberInput"
								/>
								<BaseDatePicker
									class="datepicker mb-0"
									:placeholder="$t('dateFormatPlaceholder')"
									:label="$t('coverageStart')"
									:value="coordinationOfBenefits.cobNewInfoEffDate"
									required
									:required-error-msg="$t('error.coverageStartRequired')"
									@input="onStartDateInput"
								>
								</BaseDatePicker>
							</BCol>
						</BRow>
					</BaseCard>
				</BCol>
			</BRow>
			<BRow v-if="showRecentlyEnded">
				<BCol>
					<BaseCard>
						<YesNoToggle
							automation-id="otherCoverageRecentlyEnded"
							:value="otherCoverage.otherCoverageRecentlyEnded"
							:label="$t('otherCoverageRecentlyEnded')"
							stacked
							class="mb-0"
							is-required
							@input="onOtherCoverageRecentlyEndedInput"
						></YesNoToggle>
					</BaseCard>
				</BCol>
			</BRow>
			<BRow v-if="showCoverageEnded">
				<BCol>
					<BaseCard>
						<BRow>
							<BCol cols="12" sm="10">
								<BaseDatePicker
									class="datepicker mb-0"
									:placeholder="$t('dateFormatPlaceholder')"
									:label="$t('coverageEnded')"
									:value="coordinationOfBenefits.cobPrevInfoTermDate"
									required
									:max-selectable-date="getMaxTermDate()"
									:max-error-date="getMaxTermDate()"
									:before-max-date-error-msg="$t('error.future')"
									:required-error-msg="$t('error.coverageEndRequired')"
									@input="onTermDateInput"
								>
								</BaseDatePicker>
							</BCol>
						</BRow>
					</BaseCard>
				</BCol>
			</BRow>

			<BRow>
				<BCol>
					<WizardButtonBar
						class="mt-0"
						:disable-next="invalid"
						@navigate-back="navigateBack()"
						@navigate-next="navigateNext()"
					></WizardButtonBar>
				</BCol>
			</BRow>
		</BaseForm>
	</SubmitClaimTemplate>
</template>

<script>
import Vue from 'vue';
import Component from 'vue-class-component';
import { BRow, BCol, BFormInvalidFeedback, BFormGroup } from 'bootstrap-vue';
import { format } from 'date-fns';
import { enCA, frCA } from 'date-fns/locale';
import { ValidationProvider } from 'vee-validate';
// mixins
import IdMixin from '@/mixins/id';
import BreadcrumbsManager from '@/mixins/BreadcrumbsManager';
// components
import CurrencyInput from '@/components/common/CurrencyInput';
import ErrorModal from '@/components/common/ErrorModal';
import YesNoToggle from '@/components/common/YesNoToggle';
import BaseAlert from '@/components/common/alert/BaseAlert';
import BaseDatePicker from '@/components/common/base/BaseDatePicker';
import BaseTextArea from '@/components/common/base/BaseTextArea';
import BaseButton from '@/components/common/base/BaseButton';
import BaseInput from '@/components/common/base/BaseInput.vue';
import BaseSelect from '@/components/common/base/BaseSelect';
import BaseForm from '@/components/common/base/BaseForm';
import BaseCard from '@/components/common/card/BaseCard';
import BaseRadioButtonGroup from '@/components/common/base/BaseRadioButtonGroup.vue';
import BaseCheckBox from '@/components/common/base/BaseCheckBox';
import CertificateIdInput from '@/components/common/CertificateIdInput';
import PolicyIdInput from '@/components/common/PolicyIdInput';
import WizardButtonBar from '@/components/wizard/WizardButtonBar.vue';
// pages
import SubmitClaimTemplate from '@/pages/submit-claim/SubmitClaimTemplate.vue';
// models
import Member from '@/models/MemberCardInfo';
import Dependent from '@/models/Dependent.js';
import ClaimTypes from '@/models/claims/ClaimTypes';
import COB from '@/models/claims/CoordinationOfBenefits';
import BenefitService from '@/models/claims/BenefitService';
import HsaClaims from '@/models/claims/HsaClaims';
import Page from '@/models/claims/Page';
// Constants
import { DASHBOARD, SUBMIT_CLAIM } from '@/constants/Routes.js';
import * as claimTypes from '@/constants/ClaimTypes.js';

@Component({
	name: 'OtherCoverage',
	components: {
		BRow,
		BCol,
		BaseAlert,
		BaseButton,
		BaseInput,
		BaseSelect,
		BaseCard,
		BaseDatePicker,
		BaseRadioButtonGroup,
		BaseCheckBox,
		YesNoToggle,
		BaseTextArea,
		BaseForm,
		CurrencyInput,
		CertificateIdInput,
		PolicyIdInput,
		ErrorModal,
		SubmitClaimTemplate,
		WizardButtonBar,
		ValidationProvider,
		BFormInvalidFeedback,
		BFormGroup
	},
	mixins: [IdMixin, BreadcrumbsManager]
})
export default class OtherCoverage extends Vue {
	pageName = 'otherCoverage';
	page = {};
	loading = true;
	showError = false;
	participantId = null;

	// returned from API
	claim = {};
	member = {};
	participant = {};
	coordinationOfBenefits = {};

	otherCoverage = {
		cobOnFile: null,
		stillAccurate: null,
		memberIsPrimary: null,
		otherInsuranceCoverage: null,
		otherCoverageBC: null,
		otherCoverageRecentlyEnded: null,
		otherCoverageTypeMinChecked: null,
		showIsAccurate: false,
		showAmountCoveredByOther: false,
		showOtherInsuranceCoverage: false,
		showOtherCoverageBC: false,
		showOtherPlanType: false,
		showOtherCoverageType: false,
		showTermDate: false,
		showCoverageRecentlyEnded: false
	};

	// -------------------------------------- COMPUTED PROPERTIES ----------------------------------------------

	get isDrugsOrDental() {
		return this.claim.claimType === claimTypes.DRUGS || this.claim.claimType === claimTypes.DENTAL;
	}

	get isHealth() {
		return this.claim.claimType === claimTypes.HEALTH;
	}

	// This computed prop is used to determine if we need to ask the member if the other coverage is blue cross.
	get showIsBC() {
		if (
			this.coordinationOfBenefits &&
			this.otherCoverage.stillAccurate === false &&
			this.otherCoverage.otherInsuranceCoverage
		) {
			return true;
		}
		if (
			this.otherCoverage.cobOnFile === null &&
			this.otherCoverage.otherInsuranceCoverage &&
			this.otherCoverage.showOtherCoverageBC
		) {
			return true;
		}

		return null;
	}

	// This computed prop is used to show or hide input fields if the other coverage is Blue cross or not.
	// It resets some variables when it changes in case the member toggles between the two options.
	get showIsBCCoverage() {
		if (
			this.otherCoverage.otherInsuranceCoverage &&
			this.showIsBC &&
			this.otherCoverage.otherCoverageBC !== null
		)
			return true;
		this.coordinationOfBenefits.cobNewInfoIsIndividual = null;
		this.coordinationOfBenefits.cobNewInfoHasHospital = null;
		this.coordinationOfBenefits.cobNewInfoHasVision = null;
		this.coordinationOfBenefits.cobNewInfoHasHealth = null;
		this.coordinationOfBenefits.cobNewInfoHasDental = null;
		this.coordinationOfBenefits.cobNewInfoHasDrugs = null;
		this.coordinationOfBenefits.cobNewInfoHasTravel = null;
		this.coordinationOfBenefits.cobNewInfoMemberName = null;
		this.coordinationOfBenefits.cobNewInfoIdNumber = null;
		this.coordinationOfBenefits.cobNewInfoPolicyId = null;
		this.coordinationOfBenefits.cobNewInfoEffDate = null;
		return false;
	}

	/**
	 * Computed prop to determine if we need to ask the member if their coverage has ended recently.
	 */
	get showRecentlyEnded() {
		if (!this.otherCoverage.cobOnFile && this.otherCoverage.otherInsuranceCoverage === false) {
			return true;
		} else {
			return null;
		}
	}

	/**
	 * Computed prop to determine if we display the date picker for the member to select when their coverage ended.
	 */
	get showCoverageEnded() {
		if (
			this.otherCoverage.stillAccurate === false &&
			this.otherCoverage.otherInsuranceCoverage === false
		) {
			return true;
		} else if (this.otherCoverage.otherCoverageRecentlyEnded === true) {
			return true;
		} else {
			return false;
		}
	}

	get claimType() {
		return this.claimTypes.filter((cType) => cType.claimType === this.claim.claimType)[0]
			.description;
	}

	// Gets the members full name to display if they have coverage on file.
	get memberName() {
		return `${this.participant?.firstName} ${this.participant?.lastName}`;
	}

	get cobEffectiveDate() {
		let sDate = null;
		if (this.otherCoverage.cobOnFile?.effDate) {
			if (this.securityPolicy.maaxClaims) {
				sDate = new Date(
					this.otherCoverage.cobOnFile?.effDate.substr(0, 4),
					this.otherCoverage.cobOnFile?.effDate.substr(5, 2) - 1,
					this.otherCoverage.cobOnFile?.effDate.substr(8, 2)
				);
			} else {
				sDate = new Date(
					this.otherCoverage.cobOnFile?.effDate.substr(0, 4),
					this.otherCoverage.cobOnFile?.effDate.substr(4, 2) - 1,
					this.otherCoverage.cobOnFile?.effDate.substr(6, 2)
				);
			}
			const dateLocale = this.$store.state.i18n.locale === 'en' ? enCA : frCA;
			const dateFormat = this.$store.state.i18n.locale === 'en' ? 'MMMM d, yyyy' : 'd MMMM yyyy';
			return format(sDate, dateFormat, {
				locale: dateLocale
			});
		}
		return '';
	}

	// -------------------------------------- ON LOAD METHODS ----------------------------------------------

	/**
	 * This method is used to determine if the member has skipped Cob during this claim flow.
	 */
	async beforeRouteEnter(to, from, next) {
		if (
			from.name === SUBMIT_CLAIM.CLAIM_DETAILS ||
			from.name === SUBMIT_CLAIM.HSA ||
			from.name === SUBMIT_CLAIM.ATTACH_DOCUMENTS
		) {
			const skippedCob = JSON.parse(sessionStorage.getItem('skippedCob'));
			if (skippedCob) {
				next({ name: SUBMIT_CLAIM.TREATMENT_FOR_ACCIDENT });
			}
		}
		next();
	}
	/**
	 * The created lifecycle hook is used to get all the information need to display the correct info to the member.
	 * Then it loads the page fields. The fields displayed can be different if the is on this screen for the first time, or navigated back to it.
	 *
	 */

	async created() {
		this.$store.dispatch('updateLoading', true);

		const email = sessionStorage.getItem('email');
		const token = sessionStorage.getItem('apiToken');
		const locale = this.$root.$i18n.locale;

		this.securityPolicy = JSON.parse(sessionStorage.getItem('securityPolicy'));

		this.coordinationOfBenefits = await COB.getCOBByEmail(email);
		await this.getOrCreatePage();

		if (this.claim?.participantId) {
			this.participantId = this.claim?.participantId;
		} else if (this.claim?.manualClaimServiceItems?.length > 0) {
			this.participantId = this.claim?.manualClaimServiceItems[0]?.participant;
		}

		this.claimTypes = await ClaimTypes.loadClaimType(email, token, locale);
		this.member = await Member.getMemberDetail(email, token, locale);

		await Dependent.getDependents(email, token, locale)?.then((res) => {
			this.participant = res.data.filter(
				(person) => person.participantId === this.participantId
			)[0];
		});

		await this.loadPageFields(email, token, locale);

		// Set the path for the BreadCrumbs Manager.
		this.setBreadcrumbPath(
			[
				{ text: this.$t('url.home', 'en'), to: { name: DASHBOARD } },
				{ text: this.$t('url.memberInfo', 'en') }
			],
			[
				{ text: this.$t('url.home', 'fr'), to: { name: DASHBOARD } },
				{ text: this.$t('url.memberInfo', 'fr') }
			]
		);
		this.loading = false;
		this.$store.dispatch('updateLoading', false);
	}

	/**
	 * This method is used to determine if the member has cobOnfile, or if they have existing benefit services to update, or are submitting an hsa claim.
	 * THis
	 */
	async loadPageFields(email, token, locale) {
		let cobOnFileDate;
		if (this.claim.claimType === claimTypes.HSA) {
			cobOnFileDate = this.claim?.hsaEligibleServices[0]?.serviceDate;
		} else if (this.isHealth || this.isDrugsOrDental) {
			cobOnFileDate = this.claim?.manualClaimServiceItems[0]?.dateOfService;
		} else {
			cobOnFileDate = this.claim?.benefitServices[0]?.dateOfService;
		}
		if (this.claim.claimType === claimTypes.HSA) {
			cobOnFileDate = format(
				new Date(
					this.$store.state.HSAClaimSelectDate.substr(0, 4),
					this.$store.state.HSAClaimSelectDate.substr(4, 2) - 1,
					this.$store.state.HSAClaimSelectDate.substr(6, 2)
				),
				'yyyy-MM-dd'
			);
		}
		let cobOnFileAPI = null;
		if (this.securityPolicy.maaxClaims) {
			const maaxValues = await this.getMaaxLobAndBenefit();
			const maaxCobInfo = await COB.getMaaxCob(
				email,
				token,
				locale,
				cobOnFileDate,
				maaxValues.maaxLob,
				maaxValues.maaxBenefit,
				this.participantId
			);
			if (maaxCobInfo) {
				cobOnFileAPI = {
					primaryInd: maaxCobInfo.primaryIndicator,
					carrierCode: maaxCobInfo.carrierCode ? maaxCobInfo.carrierCode : '',
					effDate: maaxCobInfo.effectiveDate,
					termDate: maaxCobInfo.terminationDate
				};
			}
		} else {
			cobOnFileAPI = await COB.getCOBInfoAPI(
				email,
				token,
				locale,
				cobOnFileDate,
				this.claim.lob,
				this.participantId
			);
		}

		if (cobOnFileAPI) {
			this.otherCoverage.cobOnFile = cobOnFileAPI;
			this.otherCoverage.showOtherInsuranceCoverage = false;
			this.otherCoverage.otherInsuranceCoverage = null;
			this.otherCoverage.showIsAccurate = true;
		} else {
			this.otherCoverage.showOtherInsuranceCoverage = true;
			this.otherCoverage.showIsAccurate = false;
		}

		if (this.otherCoverage.cobOnFile) {
			// setting otherCoverage.memberIsPrimary helps readability
			this.coordinationOfBenefits.extCovPrimaryInd = this.otherCoverage.cobOnFile.primaryInd;
			if (this.securityPolicy.maaxClaims) {
				this.otherCoverage.memberIsPrimary = !this.coordinationOfBenefits.extCovPrimaryInd;
			} else {
				this.coordinationOfBenefits.extCovPrimaryInd === 'N'
					? (this.otherCoverage.memberIsPrimary = true)
					: (this.otherCoverage.memberIsPrimary = false);
			}
			if (this.securityPolicy.maaxClaims) {
				if (this.coordinationOfBenefits.extCovPrimaryInd === true) {
					this.otherCoverage.memberIsPrimary = true;
				} else {
					this.otherCoverage.memberIsPrimary = false;
				}
			} else {
				if (this.coordinationOfBenefits.extCovPrimaryInd === 'Y') {
					this.otherCoverage.memberIsPrimary = true;
				} else {
					this.otherCoverage.memberIsPrimary = false;
				}
			}
			if (this.claim.benefitServices.length > 1 || this.claim.hsaEligibleServices.length > 1) {
				this.otherCoverage.showIsAccurate = false;
				this.otherCoverage.showOtherInsuranceCoverage = true;
			} else {
				this.otherCoverage.showIsAccurate = true;
				this.otherCoverage.showOtherInsuranceCoverage = false;
			}
		}
		// load page fields from state
		if (Object.keys(this.$store.state.submitClaim.COB).length > 0) {
			this.otherCoverage = this.$store.state.submitClaim.COB;
		}
	}

	// -------------------------------------- INPUT METHODS ----------------------------------------------

	/**
	 * This method is called when the member selects if the cob on file is still accurate.
	 * It will save the cob information if the member indicates it is accurate, or clear some fields if they indicate it is not.
	 */
	onStillAccurateInput(value) {
		this.otherCoverage.stillAccurate = value;
		for (const key in this.otherCoverage) {
			if (key.startsWith('show') && key !== 'showIsAccurate') {
				this.otherCoverage[key] = false;
			}
		}
		if (!this.otherCoverage.showOtherInsuranceCoverage) {
			this.otherCoverage.otherInsuranceCoverage = null;
		}
		// if 'is accurate'
		if (value) {
			this.coordinationOfBenefits.infoOnFileValid = true;
			this.coordinationOfBenefits.extCovCarrierCode = this.otherCoverage.cobOnFile.carrierCode;
			if (this.securityPolicy.maaxClaims) {
				this.coordinationOfBenefits.extCovTermDate = format(
					new Date(
						this.otherCoverage.cobOnFile.termDate.substr(0, 4),
						this.otherCoverage.cobOnFile.termDate.substr(5, 2) - 1,
						this.otherCoverage.cobOnFile.termDate.substr(8, 2)
					),
					'yyyy-MM-dd'
				);
			} else {
				this.coordinationOfBenefits.extCovTermDate = format(
					new Date(
						this.otherCoverage.cobOnFile.termDate.substr(0, 4),
						this.otherCoverage.cobOnFile.termDate.substr(4, 2) - 1,
						this.otherCoverage.cobOnFile.termDate.substr(6, 2)
					),
					'yyyy-MM-dd'
				);
			}
			// If member is Sask and COB is sask else member is Medavie
			if (this.member.businessOwner === '3') {
				if (this.otherCoverage.cobOnFile.carrierCode === 'SK') {
					return false;
				}
			}
			// If COB is Blue Cross
			if (
				this.coordinationOfBenefits.extCovCarrierCode === 'BC' ||
				this.coordinationOfBenefits.extCovCarrierCode === 'CB'
			) {
				return false;
			}
		} else {
			// BackEnd expects this field to be empty if the suer answers No to accurcy of CoB
			this.coordinationOfBenefits.infoOnFileValid = false;
			this.otherCoverage.showOtherInsuranceCoverage = true;
		}
	}

	/**
	 * This method is used when the member selects if they have other coverage or not.
	 * It will clear or set values depending which option the member selects.
	 */
	onOtherInsuranceCoverageInput(value) {
		this.otherCoverage.otherInsuranceCoverage = value;
		this.coordinationOfBenefits.doesMemberHaveOtherCov = value === true;
		this.otherCoverage.showOtherCoverageBC = value === true;
		if (!this.showIsBC) {
			this.otherCoverage.otherCoverageBC = null;
		}
		if (!this.showRecentlyEnded) {
			this.otherCoverage.otherCoverageRecentlyEnded = null;
		}
		this.otherCoverage.showTermDate = false;
		this.otherCoverage.showOtherPlanType = false;

		this.otherCoverage.showCoverageRecentlyEnded = value === false;

		if (!this.otherCoverage.showTermDate) {
			this.coordinationOfBenefits.cobPrevInfoTermDate = null;
		}
		// Clear all validation from the inputs.
		this.$refs.baseform.$children[0].reset();
	}

	onOtherCoverageTypeInput(value, covType) {
		this.coordinationOfBenefits[covType] = value;
		const flags = this.coordinationOfBenefits;
		this.otherCoverage.otherCoverageTypeMinChecked =
			flags.cobNewInfoHasDental ||
			flags.cobNewInfoHasDrugs ||
			flags.cobNewInfoHasHealth ||
			flags.cobNewInfoHasHospital ||
			flags.cobNewInfoHasTravel ||
			flags.cobNewInfoHasVision
				? true
				: null;
		this.isErrorMsgCoverageFor();
	}

	// For Other CoverageFor checkbox group checking if to display error message
	isErrorMsgCoverageFor() {
		this.otherCoverage.otherCoverageTypeMinChecked
			? (this.showError = false)
			: (this.showError = true);
		return this.showError;
	}

	onOtherPlanTypeInput(value) {
		this.coordinationOfBenefits.cobNewInfoIsIndividual = value;
	}

	onOtherCoverageBCInput(value) {
		this.otherCoverage.otherCoverageBC = value;
		this.otherCoverage.showOtherPlanType = true;
		this.otherCoverage.showOtherCoverageType = true;
		if (!value) {
			this.coordinationOfBenefits.cobNewInfoIdNumber = null;
			this.coordinationOfBenefits.cobNewInfoPolicyId = null;
		}
	}

	onMemberNameInput(value) {
		this.coordinationOfBenefits.cobNewInfoMemberName = value;
	}

	onIdNumberInput(value) {
		this.coordinationOfBenefits.cobNewInfoIdNumber = value;
	}

	onPolicyNumberInput(value) {
		this.coordinationOfBenefits.cobNewInfoPolicyId = value;
	}

	onStartDateInput(value) {
		this.coordinationOfBenefits.cobNewInfoEffDate = value;
	}

	onTermDateInput(value) {
		this.coordinationOfBenefits.cobPrevInfoTermDate = value;
	}

	onOtherCoverageRecentlyEndedInput(value) {
		this.otherCoverage.otherCoverageRecentlyEnded = value;
		this.coordinationOfBenefits.anyPastCovThatEnded = value === true;
		this.otherCoverage.showTermDate = value === true;
		if (!this.otherCoverage.showTermDate) {
			this.coordinationOfBenefits.cobPrevInfoTermDate = null;
		}
	}

	// -------------------------------------- NAVIGATION ----------------------------------------------

	/**
	 * Navigate to the previous screen and clear all COB info and the current page object.
	 */
	async navigateBack() {
		COB.clearCOB();
		Page.deleteById(this.pageName);
		sessionStorage.removeItem('skippedCob');
		this.$store.dispatch('submitClaimCOB', {});
	}

	async getMaaxLobAndBenefit() {
		let maaxValues = {};
		this.claimTypes.forEach((claimType) => {
			if (claimType.claimType === this.claim.claimType) {
				maaxValues = { maaxLob: claimType.maaxLob, maaxBenefit: claimType.maaxBenefit };
			}
		});
		return maaxValues;
	}

	async navigateNext() {
		await COB.updateCoordinationOfBenefits(
			sessionStorage.getItem('email'),
			this.coordinationOfBenefits
		);
		this.claim.coordinationOfBenefits = this.coordinationOfBenefits;
		this.claim.benefitServices.forEach(async (service) => {
			await BenefitService.updateBenefitServiceByEmail(sessionStorage.getItem('email'), service);
		});
		this.claim.hsaEligibleServices.forEach(async (service) => {
			await HsaClaims.updateHsaEligibleService(sessionStorage.getItem('email'), service);
		});
		await Page.updatePage(this.page, this.claim);

		this.$store.dispatch('submitClaimCOB', this.otherCoverage);
		if (
			(this.claim.claimType === claimTypes.VISION ||
				this.claim?.benefitServices[0]?.specialtyCode === 'OTHER') &&
			this.claimTypes.find((obj) => obj.claimType === 'XX-HSA') &&
			!this.securityPolicy.autoHSA
		) {
			this.$router.push({
				name: SUBMIT_CLAIM.HSA
			});
		} else if (
			this.claim.claimType === claimTypes.PRACTITIONER &&
			this.claim?.benefitServices[0]?.specialtyCode !== 'OTHER'
		) {
			this.$router.push({
				name: SUBMIT_CLAIM.CLAIM_DETAILS
			});
		} else if (
			this.claim.claimType === claimTypes.HEALTH ||
			this.claim.claimType === claimTypes.DRUGS ||
			this.claim.claimType === claimTypes.DENTAL
		) {
			this.$router.push({
				name: SUBMIT_CLAIM.SERVICE_LIST
			});
		} else {
			this.$router.push({
				name: SUBMIT_CLAIM.ATTACH_DOCUMENTS
			});
		}
	}

	getMaxTermDate() {
		return format(new Date(), 'yyyy-MM-dd');
	}

	/**
	 * This method will check to see if a page object already exists for this screen, if it does it will retrieve it and update the claim object with the value it has for the claims.
	 * If no page exists it will create a new one.
	 */
	async getOrCreatePage() {
		this.page = await Page.getPageById(this.pageName);
		if (this.page === null) {
			this.claim = structuredClone(await Page.getClaimFromLastPage());
			this.page = await Page.createNewPage(this.pageName, sessionStorage.getItem('email'));
		} else {
			this.claim = this.page?.claims[0];
			this.coordinationOfBenefits = await COB.formatCobDates(this.page?.coordinationOfBenefits);
		}
	}
}
</script>

<style lang="scss" scoped>
.label {
	font-family: $josefin-font-family;
}
.error-message {
	text-align: left;
	font-size: 14px;
	color: #ad0900;
	line-height: 17px;
	font-weight: 400;
	background-color: #fff6f6;
	border-color: #ad0900;
	padding: 10px;
	height: auto;
	border-radius: 5px;
	margin-top: 10px;
}
.text-input ::v-deep(input) {
	height: 42px;
}
.datepicker ::v-deep(input),
.datepicker ::v-deep(.btn) {
	height: 42px;
}
.datepicker ::v-deep(.form-control::placeholder) {
	letter-spacing: 0px;
	color: $black-text;
}
</style>
<i18n>
{
  "en": {
    "url": {
      "home": "Home",
      "memberInfo": "Submit a Claim"
    },
	"title": {
      "step": "Other Coverage"
    },
	"otherCoverageLabel": {
		"one": "Our records indicate that",
		"two": "has other insurance coverage since"
	},
	"stillAccurateToggleLabel": "Is this still accurate?",
	"otherInsuranceCoverageLabel": "Do you currently have any coverage under another insurance plan, even if it doesn't apply to this claim? (For example, a spouse's plan.)",
	"otherCoverageBCLabel": "Is the other coverage a Blue Cross plan?",
	"otherPlanTypeLabel": "Other plan type",
	"otherPlanTypeOptions": {
		"individual": "Individual",
		"group": "Group"
	},
	"otherCoverageTypes": {
		"hospital": "Hospital",
		"vision": "Vision",
		"extendedHealth": "Extended Health",
		"dental": "Dental",
		"drugs": "Drugs",
		"travel": "Travel"
	},
	"otherCoverageIsFor": "Other coverage is for:",
	"memberName": "Member name",
	"coverageStart": "Coverage Start Date",
	"dateFormatPlaceholder": "Select a date",
	"coverageEnded": "When did the coverage end?",
	"otherCoverageRecentlyEnded": "Is there any past coverage that recently ended?",
	"error": {
		"memberNameRequired": "The member's name is required.",
		"otherPlanTypeRequired": "Please choose the type of the other plan.",
		"otherCoverageIsFor": "Please choose a coverage area for this plan.",
		"coverageStartRequired": "Please enter the date your coverage started.",
		"coverageEndRequired": "Please enter the date your coverage ended.",
		"future": "The date you've entered is in the future. Please check the date and try again.",
		"accessibleRequired": "Required."
	}
  },
  "fr": {
    "url": {
      "home": "Accueil",
      "memberInfo": "Soumettre une demande"
    },
	"title": {
      "step": "Autre assurance"
    },
	"otherCoverageLabel": {
		"one": "Nos dossiers indiquent que",
		"two": "détient une autre couverture d'assurance depuis le"
	},
	"stillAccurateToggleLabel": "Est-ce toujours exact?",
	"otherInsuranceCoverageLabel": "Détenez-vous actuellement une couverture en vertu d'un autre régime d'assurance, même s'il ne s'applique pas à cette demande de règlement? (Par exemple, le régime de votre conjoint ou conjointe.)",
	"otherCoverageBCLabel": "L'autre couverture est-elle un régime Croix Bleue ?",
	"otherPlanTypeLabel": "Type de l'autre régime :",
	"otherPlanTypeOptions": {
		"individual": "Individuel",
		"group": "Collectif"
	},
	"otherCoverageTypes": {
		"hospital": "Hôpital",
		"vision": "Vision",
		"extendedHealth": "Soins compl.",
		"dental": "Dentaire",
		"drugs": "Médicaments",
		"travel": "Voyage"
	},
	"otherCoverageIsFor": "L'autre couverture touche la garantie :",
	"memberName": "Nom de l'adhérent",
	"coverageStart": "Date d'effet",
	"dateFormatPlaceholder": "Sélectionnez une date",
	"coverageEnded": "Quelle est la date de fin de la couverture?",
	"otherCoverageRecentlyEnded": "Déteniez-vous une couverture antérieure qui a récemment pris fin?",
	"error": {
		"otherPlanTypeRequired": "Veuillez choisir le type de l'autre régime.",
		"otherCoverageIsFor": "Veuillez choisir une couverture pour ce régime.",
		"memberNameRequired": "Le nom de l'adhérent est requis.",
		"coverageStartRequired": "Veuillez entrer la date à laquelle votre couverture a entrée en vigueur.",
		"coverageEndRequired": "Veuillez entrer la date à laquelle votre couverture a pris fin.",
		"future": "La date que vous avez entrée est ultérieure à la date d'aujourd'hui. Veuillez corriger la date et essayer à nouveau.",
		"accessibleRequired": "Requise."
	}
  }
}
</i18n>
