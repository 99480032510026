<template>
	<BaseInput
		ref="currencyInputComponent"
		class="mb-0"
		:value="formattedValue"
		:label="currencyLabelComp"
		:placeholder="isFocusedPlaceholder"
		:validation-rules="validationRules"
		:validation-mode="validationMode"
		:validation-messages="validationMessages"
		:automation-id="getAutomationId('currency')"
		:disabled="disabled"
		inputmode="decimal"
		@blur="onBlur"
		@input="onInput"
		@change="onChange"
		@keypress.native="isCurrency($event)"
		@focus="onFocus($event)"
	/>
</template>
<script>
import Vue from 'vue';
import Component from 'vue-class-component';
import IdMixin from '@/mixins/id';
import BaseInput from '@/components/common/base/BaseInput';
import { parseNumber } from '@/utils/NumberUtil';
import { required } from 'vee-validate/dist/rules';
import { extend } from 'vee-validate';
extend('required', required);
extend('isANumber', {
	validate: (value) => {
		try {
			value = parseNumber(value, document.documentElement.lang);
			return !isNaN(value);
		} catch {
			return false;
		}
	}
});
extend('isMaxValue', {
	validate: (value, { max }) => {
		if (value) {
			value = parseNumber(value, document.documentElement.lang);
			return value <= max;
		}
		return true;
	},
	params: ['max']
});
/**
 * This extended validation rule is used to check if the value entered is with the minimum limit.
 */
extend('isMinValue', {
	validate: (value, { min }) => {
		if (value) {
			value = parseNumber(value, document.documentElement.lang);
			return value >= min;
		}
		return true;
	},
	params: ['min']
});

@Component({
	name: 'CurrencyInput',
	mixins: [IdMixin],
	components: {
		BaseInput
	},
	props: {
		value: {
			type: [String, Number],
			default: null
		},
		placeholder: {
			type: String,
			default: ''
		},
		label: {
			type: String,
			default: ''
		},
		/**
		 * This prop controls the validation rules.
		 */
		validationRules: {
			type: [Object, String],
			default: ''
		},
		/**
		 * This prop controls the validation messages for each rules.
		 */
		validationMessages: {
			type: [Object, String],
			default: ''
		},
		symbole: {
			type: Boolean,
			default: false
		},
		disabled: {
			type: Boolean,
			default: false
		},
		automationId: {
			type: String,
			required: true
		},
		validationMode: {
			type: String,
			default: 'eager'
		}
	}
})
export default class CurrencyInput extends Vue {
	timeout = null;
	isFocused = false;

	locale() {
		return this.$root.$i18n.locale;
	}

	get formattedValue() {
		return this.formatToLocalizedValue(this.value);
	}

	/**
	 * These computed props check if a label was passed in,
	 * if it wasn't then it use a default label.
	 */
	get currencyLabelComp() {
		return this.label ? this.label : this.$t('label.currency');
	}
	get currencyPlaceHolder() {
		return this.$root.$i18n.locale === 'en' ? '$0.00' : '0,00$';
	}
	get requiredCurrencyMsg() {
		return this.requiredMsg ? this.requiredMsg : this.$t('error.required');
	}

	get isFocusedPlaceholder() {
		return this.isFocused ? '' : this.currencyPlaceHolder;
	}
	// /**
	//  * Emit the input event so it can be handled in the parent
	//  */
	onInput(value) {
		this.$emit('input', this.parseToNumericValue(value));
	}

	/**
	 * Emit the input change so it can be handled in the parent
	 */
	onChange(value) {
		//Strip the value from all formatting and make sure it is only a number that is emitted
		this.$emit('change', this.parseToNumericValue(value));
	}

	/**
	 * Emit the input blur (input component looses focus) so it can be handled in the parent
	 */
	async onBlur(event) {
		if (this.isFocused) this.isFocused = false;

		this.$emit('blur', event);
	}

	formatToLocalizedValue(value) {
		//Ensure we have a numeric value
		const numericValue = this.parseToNumericValue(value);
		const formatter = new Intl.NumberFormat(`${this.$root.$i18n.locale}-CA`, {
			style: 'currency',
			currency: 'CAD',
			minimumFractionDigits: 2
		});
		//Check this.numericValue != null rather than just that it exist because it will return false if
		//a valid zero is assigned to numeric value.
		if (numericValue != null && !isNaN(numericValue)) {
			let valueToreturn = formatter.format(numericValue);
			return this.$root.$i18n.locale === 'fr' ? valueToreturn.replace('CA', '') : valueToreturn;
		} else {
			return null;
		}
	}

	parseToNumericValue(value) {
		const enCurrencyRegex = /^\$?(0|[1-9][0-9]{0,2})(,*\d{3})*(\.\d{1,2})?$/i;
		const frCurrencyRegex =
			/^((?=.*[1-9]|0)(?:\d{1,3}))((?=.*\d)(?:\.*\d{3})?)*((?=.*\d)(?:,\d\d){1}?){0,1}$/i;
		let currencyLocale = null;

		// Determine the locale the member entered.
		if (enCurrencyRegex.test(String(value).trim().replace('$', ''))) {
			currencyLocale = 'en';
		} else if (frCurrencyRegex.test(String(value).trim().replace('$', ''))) {
			currencyLocale = 'fr';
		}

		return value != null && ('' + value).trim().length > 0
			? parseNumber(value, currencyLocale ? currencyLocale : this.$root.$i18n.locale)
			: null;
	}

	isCurrency(event) {
		let value = event.key;
		value = value.replace('$', '');
		//Check to see if it's a number or a alpha character
		let isCharacter = isNaN(parseFloat(value));
		let isAcceptedSpecialCharacter = false;
		//check to see if it's a comma or a dot character
		if (event.keyCode === 44 || event.keyCode === 46) {
			isAcceptedSpecialCharacter = true;
		}
		//prevent any input that isn't a number or a comma or a dot
		if (isCharacter && !isAcceptedSpecialCharacter) {
			event.preventDefault();
		}
	}

	onFocus(event) {
		this.isFocused = true;
		let target = null;
		target = document.getElementById(event.target.id);
		if (target && this.$root.$i18n.locale === 'fr') {
			target.selectionStart = target.selectionEnd = 0;
		} else if (target && this.$root.$i18n.locale === 'en') {
			target.selectionStart = target.selectionEnd = 1;
		}
	}
}
</script>
<style lang="scss" scoped>
input[type='number'] {
	-moz-appearance: textfield;
}
input[type='number']::-webkit-inner-spin-button,
input[type='number']::-webkit-outer-spin-button {
	-webkit-appearance: none;
	margin: 0;
}
</style>
<i18n>
{
	"en": {
		"label": {
			"currency": "Currency"
		},
		"error": {
			"required": "Currency input is required"
		}
	},
	"fr": {
		"label": {
			"currency": "(FR) Currency"
		},
		"error": {
			"required": "(FR)Currency input is required"
		}
	}
}
</i18n>
