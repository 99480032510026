<template>
	<SubmitClaimTemplate
		v-if="!loading"
		:percent-complete="78"
		:step-title="isDrugs || isDental ? $t('title.drugdental') : $t('title.step')"
		:class="loading ? 'invisible' : ''"
	>
		<div v-if="isDrugs">
			<p>{{ $t('drugdescription.description1') }}</p>
			<p>
				<strong>{{ $t('drugdescription.description2') }}</strong>
				{{ $t('drugdescription.description3') }}
			</p>
		</div>
		<div v-else-if="isDental">
			<p>{{ $t('dentaldescription.description1') }}</p>
			<p>
				<strong>{{ $t('dentaldescription.description2') }}</strong>
				{{ $t('dentaldescription.description3') }}
			</p>
		</div>
		<div v-else>
			<p>{{ $t('general.description1') }}</p>
			<p>
				<strong>{{ $t('general.description2') }}</strong> {{ $t('general.description3') }}
			</p>
		</div>

		<BaseForm v-slot="{ invalid }" automation-id="attachFocumentsForm'">
			<BRow class="mb-3">
				<BaseButton
					automation-id="infoModalButton"
					variant="link"
					:label="$t('modal.title')"
					:icon="['fal', 'question-circle']"
					@click="onInfoButtonClick"
				/>
			</BRow>
			<BRow>
				<BCol>
					<!-- file size limit is in bytes, so 25MB -->
					<MultiFileUpload
						:combined-file-size-limit="26214400"
						:max-files="maxDocuments"
						:initialfiles="files"
						:required="true"
						:claim-type="claim && claim.claimType"
						@change="handleFilesChange"
						@uploading="handleUploading"
					/>
				</BCol>
			</BRow>
			<BRow>
				<BCol>
					<WizardButtonBar
						class="mt-0"
						:disable-next="disableNext(invalid)"
						@navigate-back="navigateBack()"
						@navigate-next="navigateNext()"
					></WizardButtonBar>
				</BCol>
			</BRow>
		</BaseForm>

		<!-- TRAVEL -->
		<div v-if="isTravel">
			<BaseModal
				automation-id="infoModal"
				:modal-show="showInfoModal"
				:modal-title="$t('modal.title')"
				@change="closeInfoModal"
			>
				<template #contentBody>
					<div class="mb-1">
						<b>{{ $t('travel.headingOne') }}</b>
					</div>
					<ul class="mb-4">
						<li>{{ $t('travel.listOnePartOne') }}</li>
						<li>{{ $t('travel.listOnePartTwo') }}</li>
					</ul>
					<div class="mb-1">
						<b>{{ $t('travel.headingTwo') }}</b>
					</div>
					<ul class="mb-4">
						<li>{{ $t('travel.listTwoPartOne') }}</li>
						<li>{{ $t('travel.listTwoPartTwo') }}</li>
					</ul>
					<BaseButton
						variant="primary"
						:label="$t('modal.ok')"
						pill
						:automation-id="getAutomationId('infomodal-ok-button')"
						@click="closeInfoModal"
					/>
				</template>
			</BaseModal>
		</div>
		<!-- DCI -->
		<div v-else-if="isDCI">
			<BaseModal
				automation-id="infoModal"
				:modal-show="showInfoModal"
				:modal-title="$t('modal.title')"
				@change="closeInfoModal"
			>
				<template #contentBody>
					<div class="mb-1">
						<b>{{ $t('dci.labelOne') }}</b>
					</div>
					<ul class="mb-4">
						<li>{{ $t('dci.completeEmployeeStatement') }}</li>
						<li>{{ $t('dci.longTerm') }}</li>
					</ul>
					<div class="mb-1">
						<b>{{ $t('dci.labelTwo') }}</b>
					</div>
					<ul class="mb-4">
						<li>{{ $t('dci.criticalCondition') }}</li>
					</ul>
					<div class="mb-1">
						<b>{{ $t('dci.labelThree') }}</b>
					</div>
					<ul class="mb-4">
						<li>{{ $t('dci.physicianStatement') }}</li>
						<li>{{ $t('dci.otherDocs') }}</li>
					</ul>
					<BaseButton
						variant="primary"
						:label="$t('modal.ok')"
						pill
						:automation-id="getAutomationId('infomodal-ok-button')"
						@click="closeInfoModal"
					/>
				</template>
			</BaseModal>
		</div>
		<!-- LADD -->
		<div v-else-if="isLADD">
			<BaseModal
				automation-id="infoModal"
				:modal-show="showInfoModal"
				:modal-title="$t('modal.title')"
				@change="closeInfoModal"
			>
				<template #contentBody>
					<div class="mb-1">
						<b>{{ $t('ladd.labelOne') }}</b>
					</div>
					<ul class="mb-4">
						<li>{{ $t('ladd.lIADClaim') }}</li>
						<li>{{ $t('ladd.deathCert') }}</li>
					</ul>
					<div class="mb-1">
						<b>{{ $t('ladd.labelTwo') }}</b>
					</div>
					<ul class="mb-4">
						<li>{{ $t('ladd.completeAccidental') }}</li>
					</ul>
					<div class="mb-1">
						<b>{{ $t('ladd.labelThree') }}</b>
					</div>
					<ul class="mb-4">
						<li>{{ $t('ladd.terminalIllnes') }}</li>
					</ul>
					<div class="mb-1">
						<b>{{ $t('ladd.labelFour') }}</b>
					</div>
					<ul class="mb-4">
						<li>{{ $t('ladd.anyOther') }}</li>
					</ul>
					<BaseButton
						variant="primary"
						:label="$t('modal.ok')"
						pill
						:automation-id="getAutomationId('infomodal-ok-button')"
						@click="closeInfoModal"
					/>
				</template>
			</BaseModal>
		</div>
		<!-- DRUGS -->
		<div v-else-if="isDrugs">
			<BaseModal
				automation-id="infoModal"
				:modal-show="showInfoModal"
				:modal-title="$t('modal.title')"
				@change="closeInfoModal"
			>
				<template #contentBody>
					<p>{{ $t('drug.description') }}</p>
					<div class="mb-1">
						<b>{{ $t('drug.listHeader') }}</b>
					</div>
					<ul class="mb-4">
						<li>{{ $t('drug.listOptionOne') }}</li>
						<li>{{ $t('drug.listOptionTwo') }}</li>
						<li>{{ $t('drug.listOptionThree') }}</li>
						<li>{{ $t('drug.listOptionFour') }}</li>
					</ul>
					<BaseButton
						variant="primary"
						:label="$t('modal.ok')"
						pill
						:automation-id="getAutomationId('infomodal-ok-button')"
						@click="closeInfoModal"
					/>
				</template>
			</BaseModal>
		</div>
		<!-- DENTAL -->
		<div v-else-if="isDental">
			<BaseModal
				automation-id="infoModal"
				:modal-show="showInfoModal"
				:modal-title="$t('modal.title')"
				@change="closeInfoModal"
			>
				<template #contentBody>
					<p>{{ $t('dental.description') }}</p>
					<div class="mb-1">
						<b>{{ $t('dental.listHeader') }}</b>
					</div>
					<ul class="mb-4">
						<li>{{ $t('dental.listOptionOne') }}</li>
						<li>{{ $t('dental.listOptionTwo') }}</li>
						<li>{{ $t('dental.listOptionThree') }}</li>
						<li>{{ $t('dental.listOptionFour') }}</li>
						<li>{{ $t('dental.listOptionFive') }}</li>
					</ul>
					<BaseButton
						variant="primary"
						:label="$t('modal.ok')"
						pill
						:automation-id="getAutomationId('infomodal-ok-button')"
						@click="closeInfoModal"
					/>
				</template>
			</BaseModal>
		</div>
		<div v-else>
			<!-- GENERAL -->
			<BaseModal
				automation-id="infoModal"
				:modal-show="showInfoModal"
				:modal-title="$t('modal.title')"
				@change="closeInfoModal"
			>
				<template #contentBody>
					<p>{{ $t('modal.description1') }}</p>
					<div class="mb-1">
						<b>{{ $t('modal.description2') }}</b>
					</div>
					<ul class="mb-4">
						<li>{{ $t('modal.description3') }}</li>
					</ul>
					<div class="mb-1">
						<b>{{ $t('modal.description4') }}</b>
					</div>
					<ul class="mb-0">
						<li>{{ $t('modal.description5') }}</li>
						<li>{{ $t('modal.description6') }}</li>
						<li>{{ $t('modal.description7') }}</li>
					</ul>
					<BaseButton
						variant="primary"
						:label="$t('modal.ok')"
						class="mt-3"
						pill
						:automation-id="getAutomationId('infomodal-ok-button')"
						@click="closeInfoModal"
					/>
				</template>
			</BaseModal>
		</div>
	</SubmitClaimTemplate>
</template>

<script>
import Vue from 'vue';
import Component from 'vue-class-component';
import { BRow, BCol } from 'bootstrap-vue';
// mixins
import IdMixin from '@/mixins/id';
import BreadcrumbsManager from '@/mixins/BreadcrumbsManager';
// constants
import * as Claimtypes from '@/constants/ClaimTypes';

// components
import WizardButtonBar from '@/components/wizard/WizardButtonBar.vue';
import BaseForm from '@/components/common/base/BaseForm.vue';
import BaseModal from '@/components/common/base/BaseModal.vue';
import BaseButton from '@/components/common/base/BaseButton.vue';
import MultiFileUpload from '@/components/common/MultiFileUpload';
import BaseAlert from '@/components/common/alert/BaseAlert';

// Models
import Claim from '@/models/claims/Claims';
import Page from '@/models/claims/Page';
// pages
import SubmitClaimTemplate from '@/pages/submit-claim/SubmitClaimTemplate.vue';
// routes
import { DASHBOARD, SUBMIT_CLAIM } from '@/constants/Routes.js';

@Component({
	name: 'AttachDocuments',
	components: {
		BRow,
		BCol,
		BaseForm,
		SubmitClaimTemplate,
		WizardButtonBar,
		BaseModal,
		BaseButton,
		MultiFileUpload,
		BaseAlert
	},
	mixins: [IdMixin, BreadcrumbsManager]
})
export default class AttachDocuments extends Vue {
	// data
	page = {};
	claim = {};
	pageName = 'attachDocuments';
	loading = true;
	showInfoModal = false;
	files = [];
	uploading = false;

	// computed
	get maxDocuments() {
		let isFifteen = this.claim?.claimType === Claimtypes.TRAVEL || this.isDCI || this.isLADD;
		return isFifteen ? 15 : 10;
	}
	get isTravel() {
		return this.claim?.claimType === Claimtypes.TRAVEL;
	}
	get isDCI() {
		return this.claim?.claimType === Claimtypes.DCI;
	}
	get isLADD() {
		return this.claim?.claimType === Claimtypes.LADD;
	}
	get isPractitioner() {
		return this.claim?.claimType === Claimtypes.PRACTITIONER;
	}
	get isHSA() {
		return this.claim?.claimType === Claimtypes.HSA;
	}
	get isPWA() {
		return this.claim?.claimType === Claimtypes.PWA;
	}
	get isDrugs() {
		return this.claim?.claimType === Claimtypes.DRUGS;
	}
	get isDental() {
		return this.claim?.claimType === Claimtypes.DENTAL;
	}
	get isDisabilityOrLADD() {
		return this.claim?.claimType === Claimtypes.DCI || this.claim?.claimType === Claimtypes.LADD;
	}
	// methods
	onInfoButtonClick() {
		this.showInfoModal = true;
	}
	closeInfoModal() {
		this.showInfoModal = false;
	}
	navigateBack() {
		Page.deleteById(this.pageName);
		this.$store.dispatch('submitClaimFiles', []);
	}
	handleFilesChange(files) {
		this.files = files;
		this.$store.dispatch('submitClaimFiles', files);
	}
	handleUploading(value) {
		this.uploading = value;
	}
	disableNext(formInvalid) {
		if (this.uploading) {
			return true;
		}
		if (this.files.length < 1) {
			return true;
		}
		return formInvalid;
	}
	async navigateNext() {
		try {
			if (
				this.claim?.claimType === Claimtypes.HEALTH &&
				this.claim?.benefitServices[0]?.specialtyCode
			) {
				// Mobile uses specialty code in claimType but we use claimType
				// to see if we are in the correct flow so I will change it right before submitting
				this.claim.claimType = this.claim.benefitServices[0].specialtyCode;
			}

			const claim = { ...this.claim };

			await Claim.updateClaim(claim, sessionStorage.getItem('email'));
			await Page.updatePage(this.page, this.claim);

			if (
				(this.isPractitioner && this.claim?.benefitServices[0]?.specialtyCode !== 'OTHER') ||
				this.isHSA ||
				this.isPWA
			) {
				this.$router.push({
					name: SUBMIT_CLAIM.CLAIM_SUMMARY
				});
			} else {
				this.$router.push({
					name: SUBMIT_CLAIM.COMMENTS
				});
			}
		} catch (error) {
			this.$store.dispatch('error', {
				name: 'generic'
			});
		}
	}

	// lifecycle hooks
	async created() {
		this.$store.dispatch('updateLoading', true);
		await this.getOrCreatePage();
		// Set the path for the BreadCrumbs Manager.
		this.setBreadcrumbPath(
			[
				{ text: this.$t('url.home', 'en'), to: { name: DASHBOARD } },
				{ text: this.$t('url.memberInfo', 'en') }
			],
			[
				{ text: this.$t('url.home', 'fr'), to: { name: DASHBOARD } },
				{ text: this.$t('url.memberInfo', 'fr') }
			]
		);
		this.loading = false;
		this.files = this.$store.state.submitClaim.attachedFiles;
		this.$store.dispatch('updateLoading', false);
	}

	/**
	 * This method will check to see if a page object already exists for this screen, if it does it will retrieve it and update the claim object with the value it has for the claims.
	 * If no page exists it will create a new one.
	 */
	async getOrCreatePage() {
		this.page = await Page.getPageById(this.pageName);
		if (this.page === null) {
			this.claim = await Page.getClaimFromLastPage();
			this.page = await Page.createNewPage(this.pageName, sessionStorage.getItem('email'));
		} else {
			this.claim = this.page?.claims[0];
			await Claim.updateClaim(this.claim, sessionStorage.getItem('email'));
		}
	}
}
</script>

<i18n>
{
  "en": {
    "url": {
      "home": "Home",
      "memberInfo": "Submit a Claim"
		},
	"title": {
      "step": "Attach claim form and supporting documents",
	  "drugdental": "Attach receipt"
    },
	"general": {
		"description1": "Attach pictures of the required documents (such as itemized receipts, claim forms, prescriptions, or claim statements from any other carriers).",
		"description2": "Tip:",
		"description3": "for best results, make sure your image is bright and sharp. Poor quality images, missing or invalid information could lead to delays."
	},
	"drugdescription": {
		"description1": "Please attach a photo of the official prescription receipt from your pharmacy.",
		"description2": "Tip:",
		"description3": "for best results, make sure your image is bright and sharp. Poor quality images, missing or invalid information could lead to delays."
	},
	"dentaldescription": {
		"description1": "Please attach a photo of the itemized dental receipt with procedure codes.",
		"description2": "Tip:",
		"description3": "for best results, make sure your image is bright and sharp. Poor quality images, missing or invalid information could lead to delays."
	},
	"modal": {
		"title": "What documents do I need to submit?",
		"description1": "Claiming requirements can vary based on the type of benefit and your particular plan. You can find specific requirements for each benefit in the coverage section under the coverage drop down.",
		"description2": "What you'll always need:",
		"description3": "An itemized receipt of the services you received",
		"description4": "What you might need for specific benefits:",
		"description5": "A prescription from a health professional",
		"description6": "An itemized list of the services you received",
		"description7": "A medical assessment or evaluation from a health professional",
		"ok": "OK"
	},
	"ladd": {
		"labelOne": "For Life Insurance or Accidental Death claims:",
		"labelTwo": "For an Accidental Dismemberment claim:",
		"labelThree": "For a Terminal Illness claim:",
		"labelFour": "If available, please include:",
		"lIADClaim": "Your completed Group Life Claim form (FORM - 190)",
		"deathCert": "A death certificate",
		"completeAccidental": "Your completed Accidental Dismemberment Claim form (FORM - 202)",
		"terminalIllnes": "Your completed Terminal Illness Claim form (FORM - 969)",
		"anyOther": "Any other medical documentation related to this claim, such as an Attending Physician's Statement (FORM - 401)"
	},
	"dci": {
		"labelOne": "For Disability claims:",
		"labelTwo": "For Critical Illness claims:",
		"labelThree": "If available, please include:",
		"completeEmployeeStatement": "Your completed Employee Statement form (FORM - 400) from the Application for Benefits",
		"longTerm": "For a Long Term Disability claim only, proof of age (photo of driver's license, birth certificate or passport)",
		"criticalCondition": "Your completed Critical Condition Claim form (FORM - 182)",
		"physicianStatement": "An Attending Physician's Statement (FORM - 401)",
		"otherDocs": "Any other medical documentation related to this claim"
	},
	"drug": {
		"description": "Please attach a photo of the official prescription receipt from your pharmacy. ",
		"listHeader": "The prescription receipt is not the same as the cash register slip. It will show:",
		"listOptionOne": "Who the prescription is for",
		"listOptionTwo": "The Drug Identification Number (DIN)",
		"listOptionThree": "The quantity dispensed",
		"listOptionFour": "The date you received it"
	},
	"dental": {
		"description": "Please attach a photo of the itemized dental receipt with procedure codes. ",
		"listHeader": "This receipt should show:",
		"listOptionOne": "The procedure codes for the services you received",
		"listOptionTwo": "Who received the service",
		"listOptionThree": "The name of the provider",
		"listOptionFour": "The date of your visit",
		"listOptionFive": "That it has been paid in full"
	},
	"travel": {
		"headingOne": "Documents you need for every travel claim:",
		"listOnePartOne": "Your completed Travel Claim Form",
		"listOnePartTwo": "An itemized receipt of the services you received",
		"headingTwo": "Other documents you might need:",
		"listTwoPartOne": "A prescription from a health professional",
		"listTwoPartTwo": "A medical assessment or evaluation from a health professional"
	}
  },
  "fr": {
    "url": {
      "home": "Accueil",
      "memberInfo": "Soumettre une demande"
    },
	"title": {
      "step": "Joignez les formulaires et les documents justificatifs",
	  "drugdental": "Joignez un reçu"
    },
	"general": {
		"description1": "Joignez une photo des documents demandés (comme les reçus détaillés, formulaires de demande de règlement, ordonnances ou relevés de demandes de règlement provenant d'un autre assureur).",
		"description2": "Conseil :",
		"description3": "Pour de meilleurs résultats, assurez-vous que l'image est bien claire. Des images de mauvaise qualité ou des renseignements invalides ou manquants pourraient créer des retards."
	},
	"drugdescription": {
		"description1": "Veuillez joindre une photo du reçu officiel de l'ordonnance de votre pharmacie.",
		"description2": "Conseil :",
		"description3": "Pour de meilleurs résultats, assurez-vous que l'image est bien claire. Des images de mauvaise qualité ou des renseignements invalides ou manquants pourraient créer des retards."
	},
	"dentaldescription": {
		"description1": "Veuillez joindre une photo du reçu dentaire détaillé indiquant les codes de procédure.",
		"description2": "Conseil :",
		"description3": "Pour de meilleurs résultats, assurez-vous que l'image est bien claire. Des images de mauvaise qualité ou des renseignements invalides ou manquants pourraient créer des retards."
	},
	"modal": {
		"title": "Quels documents dois-je soumettre?",
		"description1": "Les exigences en matière de demandes de règlement peuvent varier selon le type de garantie et le régime. Les exigences particulières pour chaque garantie se trouvent dans la section sur la couverture du menu déroulant Couverture.",
		"description2": "Ce dont vous aurez toujours besoin :",
		"description3": "Le reçu détaillé des services que vous avez reçus",
		"description4": "Ce dont vous pourriez avoir besoin pour certaines garanties :",
		"description5": "L'ordonnance d'un professionnel de la santé",
		"description6": "Une liste détaillée des services que vous avez reçus",
		"description7": "Une évaluation médicale d'un professionnel de la santé",
		"ok": "OK"
	},
	"ladd": {
		"labelOne": "Pour une demande de prestations d'assurance vie ou d'assurance décès et mutilation par accident :",
		"labelTwo": "Pour une demande de prestations d'assurance mutilation par accident :",
		"labelThree": "Pour une demande de prestation pour maladie en phase terminale :",
		"labelFour": "S'il y a lieu, joignez les documents suivants :",
		"lIADClaim": "Le formulaire Demande de prestation d'assurance vie collective dûment rempli (FORM-190F)",
		"deathCert": "Un certificat de décès",
		"completeAccidental": "Le formulaire Demande de règlement pour mutilation par accident (FORM-202F)",
		"terminalIllnes": "Le formulaire Demande de prestation pour maladie en phase terminale (FORM-969F)",
		"anyOther": "Tout autre document médical associé à la demande de prestations, notamment la Déclaration du médecin traitant (FORM-401F)"

	},
	"dci": {
		"labelOne": "Pour les demandes de prestations d'invalidité :",
		"labelTwo": "Pour les demandes de prestations pour maladie grave :",
		"labelThree": "S'il y a lieu, joignez les documents suivants :",
		"completeEmployeeStatement": "Le formulaire Demande de prestations - Déclaration de l'employé dûment rempli (FORM-400F) ",
		"longTerm": "Dans le cas d'une demande de prestations d'invalidité de longue durée, une preuve d'âge (permis de conduire, acte de naissance ou passeport)",
		"criticalCondition": "Le formulaire Demande de prestation pour maladie grave dûment rempli (FORM-182F)",
		"physicianStatement": "La Déclaration du médecin traitant - général (FORM-401F)",
		"otherDocs": "Tout autre document médical associé à la demande de prestations"
	},
	"drug": {
		"description": "Veuillez joindre une photo du reçu officiel de l'ordonnance de votre pharmacie.",
		"listHeader": "Le reçu d'ordonnance n'est pas le même que le reçu de caisse. Il indique :",
		"listOptionOne": "le nom de la personne à qui est destinée l'ordonnance",
		"listOptionTwo": "Le numéro d'identification du médicament (DIN)",
		"listOptionThree": "La quantité délivrée",
		"listOptionFour": "La date à laquelle vous l'avez reçue"
	},
	"dental": {
		"description": "Veuillez joindre une photo du reçu dentaire détaillé indiquant les codes de procédure.",
		"listHeader": "Ce reçu doit indiquer :",
		"listOptionOne": "les codes de procédure des services reçus ",
		"listOptionTwo": "le nom de la personne qui a reçu le service",
		"listOptionThree": "le nom du fournisseur ",
		"listOptionFour": "la date de votre visite ",
		"listOptionFive": "que le paiement a été effectué dans son intégralité"
	},
	"travel": {
		"headingOne": "Documents requis pour chaque demande de règlement d'assurance voyage :",
		"listOnePartOne": "Le formulaire Demande de règlement assurance voyage dûment rempli",
		"listOnePartTwo": "Le reçu détaillé des services que vous avez reçus",
		"headingTwo": "Autres documents dont vous pourriez avoir besoin :",
		"listTwoPartOne": "L'ordonnance d'un(e) professionnel(le) de la santé",
		"listTwoPartTwo": "Une évaluation médicale d'un(e) professionnel(le) de la santé"
	}
  }
}
</i18n>
